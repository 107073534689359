<template>
  <!-- NAME[epic=绩效] 拜访日志 -->

  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="user_id">
        <el-select
          v-model="form.user_id"
          clearable
          style="width: 140px"
          placeholder="员工"
        >
          <el-option
            v-for="(i, idx) in staffList"
            :key="idx"
            :value="i.user_id"
            :label="i.user_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-val-alldata="selectCustId"
          @select-val-input="selectCustId"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.type"
          clearable
          style="width: 140px"
          placeholder="请选择类型"
        >
          <el-option
            v-for="(i, idx) in typeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="fetchData">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="list">
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '内容'">
            <div
              v-if="row.type == 2"
              class="underline"
              @click="handleVoice(row)"
            >
              <i class="el-icon-headset"></i>
              <span style="margin-left: 5px">播放音频</span>
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import ClientSearch from '@/baseComponents/clientSearch'
  import { downloadFile, postAction } from '@/api/Employee'

  export default {
    name: 'VisitLog',
    components: { ClientSearch },
    data() {
      return {
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          type: 0,
          pageSize: 10,
          pageNo: 1,
        },
        time: [],
        staffList: [],
        typeSelect: [
          {
            id: 0,
            name: '全部类型',
          },
          {
            id: 1,
            name: '文本',
          },
          {
            id: 2,
            name: '语音',
          },
        ],
        total: 0,
        list: [],
        columns: [
          {
            order: 1,
            label: '员工',
            prop: 'user_name',
            width: '',
          },
          {
            order: 2,
            label: '客户',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '内容',
            prop: 'text',
            width: '350px',
          },
          {
            order: 4,
            label: '时间',
            prop: 'create_at',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {
      postAction('/taskAdmin/visit/staff-list').then((res) => {
        this.staffList = res.data
      })
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/taskAdmin/visit/log-list',
          this.form
        )
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      selectCustId(val) {
        console.log('vvv', val)
        if (val.id) {
          this.form.cust_id = val.id
        } else {
          this.form.cust_keyword = val
        }
      },
      handleExport() {
        downloadFile(
          '/taskAdmin/visit/log-list-export',
          '拜访日志.xlsx',
          this.form
        )
      },
      handleVoice(row) {
        window.open(row.text)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
