var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "user_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "员工" },
                  model: {
                    value: _vm.form.user_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "user_id", $$v)
                    },
                    expression: "form.user_id",
                  },
                },
                _vm._l(_vm.staffList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.user_id, label: i.user_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "select-idx selectDC" },
                on: {
                  "select-val-alldata": _vm.selectCustId,
                  "select-val-input": _vm.selectCustId,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "240px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择类型" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.typeSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.fetchData } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.list },
        },
        _vm._l(_vm.columns, function (item, tableIndex) {
          return _c("el-table-column", {
            key: tableIndex,
            attrs: {
              prop: item.prop,
              label: item.label,
              width: item.width,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      item.label == "内容"
                        ? _c("div", [
                            row.type == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "underline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleVoice(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "el-icon-headset" }),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v("播放音频")]
                                    ),
                                  ]
                                )
                              : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                          ])
                        : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }